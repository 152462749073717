















































































import Repository from "../Repository"


import { Vue, Component } from 'vue-property-decorator'


@Component
export default class Partners extends Vue {

  page = ""
  selected = ""
  partners = []

  mounted() {

    Repository.get(`page/partner`)
      .then(res => {this.page = res.data})
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })

    Repository.get("partners")
      .then(res => {
      this.partners = res.data
      })
  }

}
