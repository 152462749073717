









































































































































































































































import Repository from "../Repository"
import { ContactUsType } from '../types'

import { Vue, Component } from 'vue-property-decorator'



@Component
export default class ContactUs extends Vue {

  page: string = ""

  message: ContactUsType = new ContactUsType()

  captcha_image: string | null = null
  captcha_hash: string = ""
  captcha: string = ""
  remoteURL: any = null
  maxLengthBody: number = 2000

  assertMaxChars () {

        if (this.message.body.length >= this.maxLengthBody) {
            this.message.body = this.message.body.substring(0, this.maxLengthBody);
        }
    }

  doSend() {
      if (this.message.from == "" || !this.validateEmail(this.message.from)) {
        this.$bvToast.toast("This is not valid email", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      if (this.message.name == "") {
        this.$bvToast.toast("Name is missing", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      if (this.message.phone == "") {
        this.$bvToast.toast("Phone is missing", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      if (this.message.from == "") {
        this.$bvToast.toast("Email is missing", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      if (this.message.body == "") {
        this.$bvToast.toast("Message is missing", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      // browser normally stops this
      if (this.message.body.length > 2000) {
        this.$bvToast.toast("Message is too long", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      if (this.message.image.size > 1024 * 1024)  {
        this.$bvToast.toast("Image is too large", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      if (this.captcha == "") {
        this.$bvToast.toast("CAPTCHA is missing", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      return Repository.post("send", {
        message: this.message,

        captcha_hash: this.captcha_hash,
        captcha: this.captcha
      })
        .then(res => {
          this.$bvToast.toast(res.data.content, res.data.options)
          this.message = new ContactUsType()
          // @ts-ignore:disable-next-line
          this.$refs.fileupload.value=null;
          this.fetchData()
      })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.$bvToast.toast("Email was not sent",{ "title" : "Error", "variant" : "danger"})
          this.message = new ContactUsType()
          // @ts-ignore:disable-next-line
          this.$refs.fileupload.value=null;
          this.fetchData()
      })
  }

    handleImage(e : any) {

      let files = e.target.files || e.dataTransfer.files;
        if (!files.length)  {
          console.log("Nothing")
          return;
        }

      const selectedImage = files[0] // get first file

      this.message.image_name = selectedImage.name

      this.createBase64Image(selectedImage)
    }

    createBase64Image(fileObject: any) {
      const reader = new FileReader();
      reader.onload = (e) => {


        // @ts-ignore:disable-next-line
        this.message.image = e.target.result;
        if (this.message.image.length / 1024 > this.$store.getters.pref.attachlim) {
          this.$bvToast.toast("File size is too large",{ "title" : "Error", "variant" : "danger"})
          // for some reason I have to set it then clear

          this.message = new ContactUsType()
          // @ts-ignore:disable-next-line
          this.$refs.fileupload.value=null;
        }
      };

      reader.readAsDataURL(fileObject);
    }


  validateEmail(email:string):boolean {
    var re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase())
  }

  get fileDescription(): string {
    return "JPEG, PNG, GIF, WebP only. " + this.attachlim + "Mb Max File Size"
  }

  get attachlim(): number {
      return this.$store.getters.pref.attachlim / 1024 | 0
    }


  mounted() {

    Repository.get(`page/contactus`)
      .then(res => {this.page = res.data})
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })


    this.fetchData()
  }

  fetchData() {
    this.captcha = ""

    Repository.get("captcha")
      .then(res => {
        (this.captcha_image = res.data.captcha_image),
          (this.captcha_hash = res.data.captcha_hash)
      })
      .catch(function(error) {
        // @ts-ignore:disable-next-line
        this.$bvToast.toast(JSON.stringify(error))
      })
  }

}
